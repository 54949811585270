import React, { ChangeEvent, useEffect, useState } from "react";
import "./App.scss";
import { Diagram } from "./components/diagram/Diagram";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Success } from "./components/Payment/Success/Success";
import { useAppDispatch, useAppSelector } from "./redux-store/store";
import { authThunk, logOutThunk } from "./redux-store/registration-login-auth";
import { ForgotPassword } from "./components/Forgot/ForgotPassword";
import { ResetPassword } from "./components/ResetPassword";
import { Pay } from "./components/Payment/Pay/Pay";
import "@fontsource/bebas-neue";
import "@fontsource/pacifico";
import { HashLink as Anchor } from "react-router-hash-link";
import { Osnova } from "./components/Osnova";
import { CircularProgress } from "@mui/material";
import { ErrorSnackbar } from "./feature/ErorSnackBar";
import { Footer } from "./components/Footer/Footer";
import { Welcome } from "./components/Welcome/Welcome";
import LoginIcon from "@mui/icons-material/Login";
import { Profile } from "./components/Profile/Profile";
import { SnackBarSuccess } from "./feature/SnackBarSuccess";
import { Error } from "./components/Payment/Error/Error";
import { DiagramCompatibility } from "./components/diagram/DiagramCompatibility";
import { PlusSupport } from "./feature/PlusSupport";
import { SuccessDateOnce } from "./components/Payment/Success/SuccessDateOnce";
import { SuccessLesson } from "./components/Payment/Success/SuccessLesson";
import { LearnPay } from "./components/LearnPay";
import { AdminTable } from "./Admin/component/AdminTable";
import { UserInfo } from "./Admin/component/UserInfo";
import { BlogAll } from "./components/Blog/BlogAll";
import { Blog } from "./components/Blog/Blog";
import { Konf } from "./components/OfertaKonf/Konf";
import { Oferta } from "./components/OfertaKonf/Oferta";
import { NotFound } from "./components/NotFound/NotFound";
import logotip from "./logo-icon.png";
import menuBurger from "./burger-menu.png";
import { PayLand } from "./components/Payment/Pay/PayLand";
import { SuccessLand } from "./components/Payment/Success/SuccessLand";
import RedirectToNonWWW from "./RedirectToNonWWW";
import AppLand from "./AppLand";

function App() {
  const loc = useLocation();
  const dispatch = useAppDispatch();
  const [check, setCheck] = useState(false);
  const auth = useAppSelector((state) => state.registrationReducer.auth);
  const admin = useAppSelector((state) => state.registrationReducer.admin);
  const initialize = useAppSelector((state) => state.errorReducer.initialize);
  const fromPage = loc.state?.pathname || "/";
  useEffect(() => {
    dispatch(authThunk());
  }, [dispatch]);
  useEffect(() => {
    localStorage.setItem("path", loc.pathname);
    window.scrollTo(0, 0);
  }, [loc.pathname]);

  const onClickOut = () => {
    dispatch(logOutThunk());
    setCheck(false);
  };

  if (!initialize) {
    return (
      <div
        style={{
          position: "fixed",
          top: "30%",
          textAlign: "center",
          width: "100%"
        }}
      >
        <CircularProgress style={{ color: "white" }} />
      </div>
    );
  }

  if (initialize && fromPage !== "/" && auth) {
    return <Navigate to={fromPage} replace />;
  }
  const checkBurgerFunc = (e: ChangeEvent<HTMLInputElement>) => {
    setCheck(e.currentTarget.checked);
  };

  const closeBurger = () => {
    setCheck(false);
  };
  if (loc.pathname === "/priemihovamatrix") {
    return <AppLand fi={"Мария Приемыхова"} tgName={"@mariymani"}
                    tg={"https://t.me/mariymani"}
                    youTube={"https://t.me/mariymani"}
                    tgUznat={'https://t.me/mariymani'}
                    vk={"https://vk.com/club207456675"}
                    whats={"https://t.me/mariymani"} about={'Дипломированный педагог и эксперт по нумерологии метода Матрица судьбы. Помогла уже более 1500 ученикам рассчитать свою собственную матрицу судьбы и разобраться в анализе своей Матрицы . Готова помочь и вам!'} />;

  }
  if (loc.pathname === "/zolotkomatrix") {
    return <AppLand fi={"Анна Золотько"} tgName={"@zolotkoanna4"}
                    tg={"https://t.me/zolotkoanna4"}
                    tgUznat={'https://t.me/zolotkoanna4'}
                    vk={"https://vk.com/id613241088"}
                    about={'Дипломированный педагог-психолог и эксперт по нумерологии метода Матрица души. Помогла разобрать свои матрицы более 100 клиентам. Готова помочь и Вам!'} />;

  }
  if (loc.pathname === "/godynovamatrix") {
    return <AppLand fi={"Наталья Годунова"} tgName={"@natalia_numerologMS"}
                    tgUznat={'https://t.me/natalia_numerologMS'}
                    tg={"https://t.me/numerologGodunova"}
                    vk={"https://vk.com/numerolog_godunowa"}
                    about={'Эксперт по нумерологии метода Матрицы судьбы с образованием психолога. С помощью анализа Матрицы судьбы я изменила свою жизнь к лучшему и помогла более 150 клиентам. Помогу и вам!'} />;

  }
  return (
    <>
      <RedirectToNonWWW />
      <div className={"App"}>
        {/*<img width={300} src={''} alt="" />*/}
        {/*<div className={'errorTechnik'}>На сайте ведутся техниеские работы</div>*/}
        <PlusSupport />
        {loc.pathname === "/welcome" ? undefined : (
          <div className={"header"}>
            <Link onClick={closeBurger} to={"/"}>
              <img src={logotip as string} alt="Логотип" />
            </Link>
            <div className={`headerList ${check ? "activeBurger" : ""}`}>
              <div className={"divOpacity"}></div>
              <div className={"miniHeaderList"}>
                <Anchor
                  onClick={closeBurger}
                  smooth
                  to={"/#sectionDateScroll"}
                  className={"menuSpan"}
                >
                  Калькулятор
                </Anchor>
                <Anchor
                  onClick={closeBurger}
                  smooth
                  to={"/#methodScroll"}
                  className={"menuSpan"}
                >
                  О методе
                </Anchor>
                <Link
                  onClick={closeBurger}
                  className={"menuSpan"}
                  to={"/blog"}
                >
                  Блог
                </Link>
                <Link
                  onClick={closeBurger}
                  className={"menuSpan"}
                  to={"https://t.me/Prognoz_matricadyshi_bot"}
                >
                  Бот Прогноза NEW
                </Link>
                <Link
                  onClick={closeBurger}
                  className={"menuSpan"}
                  to={"/learn"}
                >
                  Обучение
                </Link>
                <Anchor
                  onClick={closeBurger}
                  smooth
                  to={"/#tarif"}
                  className={"menuSpan"}
                >
                  Тарифы
                </Anchor>
                {admin ? (
                  <Link
                    onClick={closeBurger}
                    style={{ marginRight: 20, fontSize: 16 }}
                    className={"menuSpan"}
                    to={"/admin-table"}
                  >
                    Админ
                  </Link>
                ) : undefined}
                {auth ? <>
                  <div className={"divLine"}></div>
                  <Link
                    onClick={closeBurger}
                    className={"menuSpan noneKomp"}
                    to={"/profile/tarif"}
                  >
                    Мой тариф
                  </Link>
                  <Link
                    onClick={closeBurger}
                    className={"menuSpan noneKomp"}
                    to={"/profile/mymatrix"}
                  >
                    История поиска матриц
                  </Link>
                  <Link
                    onClick={closeBurger}
                    className={"menuSpan noneKomp"}
                    to={"/profile/orders"}
                  >
                    История заказов
                  </Link>
                  <Link
                    onClick={closeBurger}
                    className={"menuSpan noneKomp"}
                    to={"https://t.me/Prognoz_matricadyshi_bot"}
                  >
                    Бот Прогноза NEW
                  </Link>
                  <Link
                    onClick={closeBurger}
                    className={"menuSpan noneKomp"}
                    to={"/profile/learn"}
                  >
                    Видео-Обучение
                  </Link>
                  <Link
                    onClick={closeBurger}
                    className={"menuSpan noneKomp"}
                    to={"/profile/support"}
                  >
                    Написать в поддержку
                  </Link>
                  <span className={"menuSpan outClass"}
                        onClick={onClickOut}>Выйти из аккаунта
                </span>
                </> : undefined}
              </div>
            </div>
            {auth ? (
              <>
                <div className={"divLog"}>
                  <Link
                    onClick={closeBurger}
                    style={{ fontSize: 16 }}
                    className={"batonStandart"}
                    to={"/profile/user"}
                  >
                    Профиль
                  </Link>
                  <input
                    onChange={checkBurgerFunc}
                    checked={check}
                    type="checkbox"
                    id="toggle-menu"
                  />
                  <label htmlFor="toggle-menu" className="burger-icon">
                    <img src={menuBurger as string} alt="" />
                  </label>
                </div>
              </>
            ) : (
              <>
                <div className={"divLog"}>
                  <Link
                    style={{ marginRight: 20 }}
                    className={"batonStandart"}
                    to={"/welcome"}
                  >
                    Войти <LoginIcon />
                  </Link>
                  <input
                    onChange={checkBurgerFunc}
                    checked={check}
                    type="checkbox"
                    id="toggle-menu"
                  />
                  <label htmlFor="toggle-menu" className="burger-icon">
                    <img src={menuBurger as string} alt="" />
                  </label>
                </div>
              </>
            )}
          </div>

        )}
        <Routes>
          <Route path={"/"} element={<Osnova />} />
          <Route path={"/matrix"} element={<Diagram />} />
          <Route
            path={"/matrixcompatibility"}
            element={<DiagramCompatibility />}
          />
          <Route path={"/success"} element={<Success />} />
          <Route path={"/success-land"} element={<SuccessLand />} />
          <Route path={"/learn"} element={<LearnPay />} />
          <Route path={"/success-date-once"} element={<SuccessDateOnce />} />
          <Route path={"/success-lesson"} element={<SuccessLesson />} />
          <Route path={"/error"} element={<Error />} />
          <Route path={"/welcome"} element={<Welcome />} />
          <Route path={"/forgot"} element={<ForgotPassword />} />
          <Route path={"/reset-password/:token"} element={<ResetPassword />} />
          <Route path={"/pay"} element={<Pay />} />
          <Route path={"/payland"} element={<PayLand />} />
          <Route path={"/profile/*"} element={<Profile />} />
          <Route path="/blogs" element={<BlogAll />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path={"/blog"} element={<BlogAll />} />
          <Route path={"/konf"} element={<Konf />} />
          <Route path={"/oferta"} element={<Oferta />} />
          <Route path={"/admin-table"} element={<AdminTable />} />
          <Route path={"/admin-table/:id"} element={<UserInfo />} />
          <Route path={"/not-found"} element={<NotFound />} />
          <Route path={"/priemihovamatrix"} element={<AppLand />} />
          <Route path={"/zolotkomatrix"} element={<AppLand />} />
          <Route path={"/godynovamatrix"} element={<AppLand />} />
          <Route path={"*"} element={<Navigate to="/not-found" />} />
        </Routes>
        <ErrorSnackbar />
        <SnackBarSuccess />
        {loc.pathname === "/welcome" ? undefined : <Footer />}
      </div>
    </>);
}

export default App;
