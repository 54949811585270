import s from "./AppLand.module.scss";
import { MatrixDiagram } from "./componentsLand/MatrixDiagram";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CiYoutube } from "react-icons/ci";
import { SlSocialVkontakte } from "react-icons/sl";
import { LiaTelegramPlane } from "react-icons/lia";
import { FaWhatsapp } from "react-icons/fa";
import { MatrixDiagramCompatibility } from "./componentsLand/MatrixDiagramCompatibility";

function AppLand(props: {
  fi?: string,
  tgName?: string,
  tg?: string,
  about?: string,
  vk?: string | undefined
  whats?: string | undefined
  youTube?: string | undefined
  tgUznat?: string
}) {
  const [value, setValue] = useState<string>("");
  const [value1, setValue1] = useState<string>("");
  const [value2, setValue2] = useState<string>("");
  const [dateValue, setDateValue] = useState<string>("");
  const [matr, setMatr] = useState<"personal" | "compatibility">("personal");

  const [dateValue1, setDateValue1] = useState<string>("");
  const [dateValue2, setDateValue2] = useState<string>("");
  const [scrollToElementId, setScrollToElementId] = useState<string | null>(null);

  useEffect(() => {
    if (scrollToElementId) {
      const element = document.getElementById(scrollToElementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setScrollToElementId(null);
      }
    }
  }, [scrollToElementId]);
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let inputDate = e.target.value.replace(/\D/g, "");
    if (inputDate.length > 8) {
      inputDate = inputDate.slice(0, 8);
    }
    if (inputDate.length >= 5) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2, 4)}-${inputDate.slice(4)}`;
    } else if (inputDate.length >= 3) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2)}`;
    }
    setValue(inputDate);
  };


  const onChangeHandler1 = (e: ChangeEvent<HTMLInputElement>) => {
    let inputDate = e.target.value.replace(/\D/g, "");
    if (inputDate.length > 8) {
      inputDate = inputDate.slice(0, 8);
    }
    if (inputDate.length >= 5) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2, 4)}-${inputDate.slice(4)}`;
    } else if (inputDate.length >= 3) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2)}`;
    }
    setValue1(inputDate);
  };


  const onChangeHandler2 = (e: ChangeEvent<HTMLInputElement>) => {
    let inputDate = e.target.value.replace(/\D/g, "");
    if (inputDate.length > 8) {
      inputDate = inputDate.slice(0, 8);
    }
    if (inputDate.length >= 5) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2, 4)}-${inputDate.slice(4)}`;
    } else if (inputDate.length >= 3) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2)}`;
    }
    setValue2(inputDate);
  };

  function buttonClick1() {
    setMatr("personal");
    setDateValue1("");
    setDateValue2("");
    setValue1("");
    setValue2("");
  }

  function buttonClick2() {
    setMatr("compatibility");
    setDateValue("");
    setValue("");
  }

  return (
    <div className={s.AppLand}>
      <div className={s.glava}>
        <div className={s.profile}>
          <div className={s.fotoAndName}>
            <div className={s.name}>
              <span>{props.fi}</span>
              <span className={s.tgname}>{props.tgName}</span>
            </div>
          </div>
          <span className={s.about}>{props.about}</span>
          <div>
            {props.tg ? <Link to={props.tg as string}><LiaTelegramPlane className={s.iconLand} /></Link> : undefined}
            {props.youTube ? <Link to={props.youTube}><CiYoutube className={s.iconLand} /></Link> : undefined}
            {props.vk ? <Link to={props.vk as string}><SlSocialVkontakte className={s.iconLand} /></Link> : undefined}
            {props.whats ? <Link to={props.whats as string}><FaWhatsapp className={s.iconLand} /></Link> : undefined}
          </div>
          <button onClick={() => setScrollToElementId("divDateButtonScroll")}
                  className={s.batonStandartSearch} style={{ width: "100%", marginTop: 20 }}>Рассчитать свою
            Матрицу Судьбы
          </button>
        </div>
      </div>
      <div id={"divDateButtonScroll"} className={s.divDateButton}>
        <span className={s.spanMatrix}>Рассчитайте свою Матрицу Судьбы</span>


        <div className={s.batonDiv}>
          <button className={`${s.baton} ${matr !== "personal" ? s.override : ""}`} onClick={buttonClick1}>
            Персональная
          </button>
          <button className={`${s.baton} ${matr === "personal" ? s.override : ""}`} onClick={buttonClick2}>
            Совместимость
          </button>
        </div>
        {matr === "personal" ? <input
          className={s.dateMatrix}
          onChange={onChangeHandler}
          value={value}
          placeholder="дд-мм-гггг"
        /> : <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{marginTop: '10px'}}>1-го партнера</span>
          <input
            className={s.dateMatrix}
            onChange={onChangeHandler1}
            value={value1}
            placeholder="дд-мм-гггг"
          />
          <span >2-го партнера</span>
          <input
            className={s.dateMatrix}
            onChange={onChangeHandler2}
            value={value2}
            placeholder="дд-мм-гггг"
          />
        </div>}
        <button
          className={`${s.batonStandartSearch} ${matr === "personal"
            ? (value.length !== 10 ? "disabled-link" : "")
            : matr === "compatibility"
              ? (value1.length !== 10 || value2.length !== 10 ? "disabled-link" : "")
              : ""}`}
          onClick={(e) => {
            if (matr === "personal") {
              // Проверка для режима personal
              if (value.length !== 10) {
                e.preventDefault();
                return;
              }
              setDateValue(value.split("-").reverse().join("-"));
            } else if (matr === "compatibility") {
              // Проверка для режима compatibility
              if (value1.length !== 10 || value2.length !== 10) {
                e.preventDefault();
                return;
              }
              setDateValue1(value1.split("-").reverse().join("-"));
              setDateValue2(value2.split("-").reverse().join("-"));
            }

            // Прокрутка после установки значений
            setTimeout(() => {
              const element = document.getElementById("methodScroll");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }, 100); // Задержка 100 мс
          }}
        >
          Рассчитать
        </button>


      </div>
      {dateValue ? <MatrixDiagram tgUznat={props.tgUznat as string} date={dateValue} /> : null}
      {dateValue1 && dateValue2 ?
        <MatrixDiagramCompatibility tgUznat={props.tgUznat as string} date1={dateValue1} date2={dateValue2} /> : null}
      <div className={s.uslugi}>
        <div style={{ margin: "30px auto" }}>
          {props.tg ? <Link to={props.tg as string}><LiaTelegramPlane className={s.iconLand} /></Link> : undefined}
          {props.youTube ? <Link to={props.youTube}><CiYoutube className={s.iconLand} /></Link> : undefined}
          {props.vk ? <Link to={props.vk as string}><SlSocialVkontakte className={s.iconLand} /></Link> : undefined}
          {props.whats ? <Link to={props.whats as string}><FaWhatsapp className={s.iconLand} /></Link> : undefined}
        </div>
        <span className={s.matrix}>Сайт сделан <Link style={{ color: "black", fontWeight: "bold" }}
                                                     to={"https://t.me/mariymani"}>тут</Link></span>
      </div>
    </div>
  );
}

export default AppLand;



