import React from "react";
import { Card } from "@mui/material";
import "./Payment.scss";
import { Link } from "react-router-dom";
import mark from "../../check-mark.png";
import { HashLink as Anchor } from "react-router-hash-link";
import { useAppSelector } from "../../redux-store/store";
export const Payment = () => {
  // const [isDisabled, setIsDisabled] = useState(true);
  const auth = useAppSelector((state) => state.registrationReducer.auth);
  return (
    <div id={"tarif"} className={"backColor"}>
      <div className={"backImageKosmosPay"}></div>
      <div className={"allPay"}>
        <span className={"spanTarifGlav"}>тарифы</span>
        <p className={"tarifText"}>Измени свою жизнь к лучшему уже сейчас</p>
        <div className={"listPay"}>
            <Card className={"card"}>
              <span className={"spanPay"}>Матрица на месяц</span>
              <div className={"divTarif"}>
                <div className={"divPrice"}>
                  <span className={"price"}>1290</span>
                  <br />
                  <span className={"tarifRubl"}>рублей</span>
                </div>
                <ul>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} /> Расшифровка детской матрицы
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Расшифровка совместимости
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Полная расшифровка Матрицы
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    <span> Количество дат к проверке <br /> не ограничено</span>
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    <span>Доступ <b>НА 1 МЕСЯЦ</b></span>
                  </li>
                </ul>
              </div>
              <Link
                className={"baton"}
                to={"/pay"}
                style={{ width: "100%", fontWeight: "bold", padding: 10, fontSize: 16, textAlign: "center" }}
                // state={{ name: "Матрица на месяц", price: 100 }}
                state={{ name: "Матрица на месяц", price: 129000 }}
              >
                Приобрести
              </Link>
            </Card>
            <Card className={"card"}>
              <span className={"spanPay"}>Матрица на год</span>
              <div className={"divTarif"}>
                <div className={"divPrice"}>
                  <span className={"price"}>2990</span>
                  <br />
                  <span className={"tarifRubl"}>рублей</span>
                </div>
                <ul>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} /> Расшифровка детской матрицы
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Расшифровка совместимости
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Полная расшифровка Матрицы
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Количество дат к проверке <br /> не ограничено
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    <span> Доступ <b>НА 1 ГОД</b></span>
                  </li>
                </ul>
              </div>
              <Link
                className={"baton"}
                to={"/pay"}
                style={{ width: "100%", fontWeight: "bold", padding: 10, fontSize: 16, textAlign: "center" }}
                state={{ name: "Матрица на год", price: 299000 }}
              >
                Приобрести
              </Link>
            </Card>

            <Card className={"card"}>
              <span className={"spanPay"}>Раз и навсегда</span>
              <div className={"divTarif"}>
                <div className={"divPrice"}>
                  <span className={"price"}>6990</span>
                  <br />
                  <span className={"tarifRubl"}>рублей</span>
                </div>
                <ul>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Расшифровка детской матрицы
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Расшифровка совместимости
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Полная расшифровка Матрицы
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Количество дат к проверке <br /> не ограничено
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    <span> Доступ <b>НАВСЕГДА</b> </span>
                  </li>
                </ul>
              </div>
              <Link
                className={"baton"}
                to={"/pay"}
                style={{ width: "100%", fontWeight: "bold", padding: 10, fontSize: 16, textAlign: "center" }}
                state={{ name: "Вместе и навсегда", price: 699000 }}
              >
                Приобрести
              </Link>
            </Card>
            <Card className={"card"}>
              <span className={"spanPay"}>Пробный</span>
              <div className={"divTarif"}>
                <div className={"divPrice"}>
                  <span className={"price"}>290</span>
                  <br />
                  <span className={"tarifRubl"}>рублей</span>
                </div>
                <ul>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />1 Расшифровка Матрицы судьбы
                    <br />
                    или
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />1 Расшифровка совместимости
                    <br />
                    или
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />1 Расшифровка Детской
                    Матрицы
                  </li>
                </ul>
              </div>
              <Link
                className={"baton"}
                to={"/pay"}
                style={{ width: "100%", fontWeight: "bold", padding: 10, fontSize: 16, textAlign: "center" }}
                state={{ name: "Пробный", price: 29000 }}
              >Приобрести
              </Link>
            </Card>


            <Card className={"card"}>
              <span className={"spanPay"}>Свой калькулятор по Матрице судьбы</span>
              <div className={"divTarif"}>
                <div className={"divPrice"}>
                  <span className={"price"}>3590</span>
                  <br />
                  <span className={"tarifRubl"}>рублей</span>
                </div>
                <ul>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Ваш индивидуальный калькулятор для расчета Матрицы Судьбы (без расшифровок) с кнопкой для перехода
                    на ваши социальные сети
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Делись своей ссылкой на калькулятор и направляй клиентов в свои социальные сети
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Пример по <span>&nbsp;</span> <Link to={"/priemihovamatrix"}>ССЫЛКЕ</Link>
                  </li>
                </ul>
              </div>
              <Link
                style={{ width: "100%", fontWeight: "bold", padding: 10, fontSize: 16, textAlign: "center" }}
                className={"baton"}
                to={`/${auth ? "payland" : "welcome"}`}
                // to={'/payland'}
                state={{
                  name: "Свой сайт-калькулятор по Матрице судьбы",
                  price: 359000
                }}
              >
                Приобрести
              </Link>
            </Card>

            <Card className={"card"}>
              <span className={"spanPay"}>Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица на год</span>
              <div className={"divTarif"}>
                <div className={"divPrice"}>
                  <span className={"price"}>19990</span>
                  <br />
                  <span className={"tarifRubl"}>рублей</span>
                </div>
                <ul>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />Вы получаете программу онлайн-курса по Матрице Судьбы с
                    получением диплома государственного образца
                  </li>
                  <li className={"ulPay"}>
                    <img src={mark} alt="" className={"ic"} />
                    Доступ к матрице на год
                  </li>
                </ul>
              </div>
              <Anchor
                className={"baton"}
                to={"/learn/#LearnDesck"}
                smooth
                style={{ width: "100%", fontWeight: "bold", padding: 10, fontSize: 16, textAlign: "center" }}
              >
                Подробнее
              </Anchor>
            </Card>
        </div>
        <p
          className={"pVajno"}
          style={{
            textAlign: "start",
            color: "red",
            fontSize: 25,
            marginBottom: 50
          }}
        >
          Все приобретенные даты по подписке будут доступны Вам навсегда в разделе
          "История поиска матриц".
          <br />
          При приобретении тарифа "Пробный" повторно, количество расшифровок
          прибавляется к уже ранее приобретенным.
          <br />
          При приобретении тарифа «Матрица на месяц» повторно, месяц прибавляется к ранее приобретенному месяцу.
          <br />
          При приобретении тарифа «Раз и навсегда», вы получаете доступ бессрочно.
        </p>
      </div>
    </div>
  );
};
