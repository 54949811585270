import React, { ChangeEvent, useState } from "react";
import { useAppSelector } from "../../../redux-store/store";
import { Link, useLocation } from "react-router-dom";
import { authAPI } from "../../../API/API";
import { HashLink as Anchor } from "react-router-hash-link";
import { Checkbox, createTheme, FormControlLabel, Theme, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { SubmitHandler, useForm } from "react-hook-form";
import "./Pay.scss";
import { useTheme } from "@mui/material/styles";
import rus from "../../../flag.png";
import mir from "../../../mir.png";
import uploadNik from "../../../uploadNikTelegram.png";
import uploadDesc from "../../../uploadDesc.png";
import FormHelperText from "@mui/material/FormHelperText";
import {
  descValidation,
  nikValidation, telegaValidation
} from "../../../validations/validationRegistrationForm";
import off from "../../../off.docx";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "white",
            "--TextField-brandBorderColor": "white",
            "--TextField-brandBorderHoverColor": "white",
            "--TextField-brandBorderFocusedColor": "white",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)"
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "white",
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)"
            },

            "&.Mui-disabled": {
              "&::before": {
                borderBottom: "2px solid white"
              }
            },
            fontFamily: "Golos"
          }
        }
      }
    }
  });
const policyFunc = (auth: boolean) => {
  if (auth) {
    return (
      <span style={{ fontSize: 16 }} className={"spanOferta"}>
        Продолжая покупку, я принмаю условия{" "}
        <Link style={{ color: "rgba(196, 157, 214, 1)" }} to={"/oferta"}>Публичной оферты</Link>, <a
        style={{ color: "rgba(196, 157, 214, 1)", textDecoration: "underline" }}
        className={"linkFooter"} href={off} target="_blank" rel="noopener noreferrer">
            договор-оферта
            об оказании платных образовательных услуг
          </a>, подтверждая, что
        ознакомился(ась) с{" "}
        <Anchor style={{ color: "rgba(196, 157, 214, 1)" }} smooth to={"/oferta/#personalData"}>
          Политикой обработки персональных данных
        </Anchor>{" "}
        и даю свое согласие на обработку персональных данных
      </span>
    );
  } else {
    return (
      <span style={{ fontSize: 16 }} className={"spanOferta"}>
        Продолжая покупку и регистрацию, я принмаю условия{" "}
        <Link style={{ color: "rgba(196, 157, 214, 1)" }} to={"/oferta"}>Публичной оферты</Link>, <a
        style={{ color: "rgba(196, 157, 214, 1)", textDecoration: "underline" }}
        className={"linkFooter"} href={off} target="_blank" rel="noopener noreferrer">
            договор-оферта
            об оказании платных образовательных услуг
          </a>, подтверждая, что
        ознакомился(ась) с{" "}
        <Anchor style={{ color: "rgba(196, 157, 214, 1)" }} smooth to={"/oferta/#personalData"}>
          Политикой обработки персональных данных
        </Anchor>{" "}
        и даю свое согласие на обработку персональных данных
      </span>
    );
  }
};

interface FormRegistration {
  telega: string;
  desc: string;
  nik: string;
  check: boolean;
}

export const PayLand = () => {
  const outerTheme = useTheme();
  const { state } = useLocation();
  const name = useAppSelector((state) => state.registrationReducer.name);
  const surname = useAppSelector((state) => state.registrationReducer.surname);
  const mail = useAppSelector((state) => state.registrationReducer.email);
  const id = useAppSelector((state) => state.registrationReducer.id);
  const auth = useAppSelector((state) => state.registrationReducer.auth);
  const [sng, setSng] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const pol = policyFunc(auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");


  const [whats, setWhats] = React.useState("");
  const [vk, setVk] = React.useState("");
  const [youTube, setYouTube] = React.useState("");

  const openModal = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    register
  } = useForm<FormRegistration>({
    mode: "all"
  });
  const closeModal = () => {
    setModalOpen(false);
  };



  const onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setCheck(e.currentTarget.checked);
  };


  const onSubmit: SubmitHandler<FormRegistration> = async (data) => {
    await authAPI.matrixOnce({
      id,
      item: {
        username: name,
        surname: surname,
        name: state.name,
        price: state.price,
        desc: data.desc,
        nik: data.nik,
        whats,
        youTube,
        vk,
        telega: data.telega,
        mail
      }
    });
  };


  const onSubmitSNG: SubmitHandler<FormRegistration> = async (data) => {
    await authAPI.matrixOnceSNG({
      id,
      item: {
        username: name,
        surname: surname,
        name: state.name,
        price: Math.floor(state.price / 100),
        mail,
        desc: data.desc,
        nik: data.nik,
        whats,
        youTube,
        vk,
        telega: data.telega,
      }
    });
  };


  const total = state.price.toString().replace(/(\d+)00(?=\s|$)/g, "$1,00");
  return <div className={"paySectionLand"}>
    <form style={{maxWidth: '100%'}} onSubmit={sng ? handleSubmit(onSubmitSNG) : handleSubmit(onSubmit)}>
      <span className={"spanWelcome"}>Оформление покупки</span>
      <div className={"divUslugi"}>
        <span className={"spanPay"}>*Разработка Вашего сайта займет 1-2 дня</span>
        <span className={"spanPay"}>*Ссылка на Ваш готовый сайт появится в разделе "Мой тариф"</span>
        <span className={"spanPay"}>*В цену входит доменное имя и хостинг сайта</span>
        <span className={"spanPay"}>*Ваше доменное имя будет состоять из вашей фамилии латинскими буквами и слово 'matrix' на конце</span>
        <span className={"spanPay"}>*Пример доменного имени:
          https://matricadyshi.ru/#/priemihovamatrix</span>
      </div>
      <div className={"divUserForm"}>
        <span className={"spanUser"}>Имя</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={name}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Фамилия</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={surname}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Email</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={mail}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>
      <div style={{ marginTop: 30 }} className={"imgDiv"}>
        <img
          className={"imgSertificate"}
          src={uploadNik}
          alt="Диплом"
          onClick={() => openModal(uploadNik)}
        />
      </div>
      {modalOpen && (
        <div className="modal-container">
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal-content">
            <img src={selectedImage} alt="Full-screen" />
          </div>
        </div>
      )}

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Введите Ваш ник в Telegram</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              {...register("nik", nikValidation)}
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Golos",
                  paddingLeft: 10
                }
              }}
              multiline
              label={"Ник"}
              variant="standard"
            />
          </ThemeProvider>
          {!errors.nik?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
            <FormHelperText error={!!errors.nik?.message}>
              {errors.nik?.message}
            </FormHelperText>}
        </FormControl>
      </div>

      <div style={{ marginTop: 30 }} className={"imgDiv"}>
        <img
          className={"imgSertificate"}
          src={uploadDesc}
          alt="Диплом"
          onClick={() => openModal(uploadDesc)}
        />
      </div>
      {modalOpen && (
        <div className="modal-container">
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal-content">
            <img src={selectedImage} alt="Full-screen" />
          </div>
        </div>
      )}
      <div className={"divUserForm"}>
        <span className={"spanUser"}>Введите Ваше описание</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              {...register("desc", descValidation)}
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Golos",
                  paddingLeft: 10
                }
              }}
              multiline
              label={"Описание"}
              variant="standard"
            />
          </ThemeProvider>
          {!errors.desc?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
            <FormHelperText error={!!errors.desc?.message}>
              {errors.desc?.message}
            </FormHelperText>}
        </FormControl>
      </div>

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Введите ссылку на Ваш Telegram</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              {...register("telega", telegaValidation)}
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Golos",
                  paddingLeft: 10
                }
              }}
              multiline
              label={"Ссылка Telegram"}
              variant="standard"
            />
          </ThemeProvider>
          {!errors.telega?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
            <FormHelperText error={!!errors.telega?.message}>
              {errors.telega?.message}
            </FormHelperText>}
        </FormControl>
      </div>


      <div className={"divUserForm"}>
        <span className={"spanUser"}>Введите ссылку на Ваш VKontakte</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Golos",
                  paddingLeft: 10
                }
              }}
              onChange={e => setVk(e.currentTarget.value)}
              value={vk}
              multiline
              label={"Ссылка VKontakte"}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>


      <div className={"divUserForm"}>
        <span className={"spanUser"}>Введите ссылку на Ваш YouTube</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Golos",
                  paddingLeft: 10
                }
              }}
              onChange={e => setYouTube(e.currentTarget.value)}
              value={youTube}
              multiline
              label={"Ссылка YouTube"}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Тариф</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "rgba(196, 157, 214, 1)",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={state.name}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Стоимость</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "rgba(196, 157, 214, 1)",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={`${total} руб.`}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>
      <FormControlLabel
        style={{ margin: "15px auto", color: "white" }}
        className={"saveMe"}
        control={<Checkbox onChange={onChangeCheck} style={{
          color: "rgba(104, 74, 134, 1)",
          width: 16,
          height: 16,
          backgroundColor: "white",
          margin: "0 10px 0 0",
          padding: 0,
          borderRadius: 0
        }} defaultChecked={false} />}
        label={pol}
      />
      <button
        style={{
          width: "90%", fontSize: 16, position: "relative",
          margin: "10px auto 10px auto ",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        className={`batonPay + ' ' + ${!isValid || !check ? "dis" : ""}`}
        disabled={!check}
        type={"submit"}
        onClick={() => setSng(false)}
      >
        Оплатить для РФ
        <img className={"imgOplata"} src={rus} alt="" />
      </button>
      <button
        style={{
          width: "90%", margin: "0 auto", fontSize: 16, position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        className={`batonPay + ' ' + ${!isValid || !check ? "dis" : ""}`}
        disabled={!check}
        type={"submit"}
        onClick={() => setSng(true)}
      >
        Оплатить для других стран
        <img className={"imgOplata"} src={mir} alt="" />
      </button>
    </form>
  </div>
    ;
};
