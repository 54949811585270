import React, { useState } from "react";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import "./LearnPay.scss";
import sertificateImage from "../sertificate.png";
import diplom from "../diplom.png";
import star from "../bullet-star.png";
import mark from "../check-mark.png";
import pdfUrl from "../doc.pdf"; // Укажите путь к вашему PDF

export const LearnPay = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const openModal = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage("");
    setModalOpen(false);
  };
  return (
    <div className={"learnDiv"}>
      <span className={"spanLearn"}>Программы обучения</span>
      <div className={"learnProgram"}>
        <div className={"learnDescription"} id={"LearnDesck"}>
          <span className={"learnSpanKourse"}>
            Получите диплом государственного образца по программе онлайн-курса "Матрица Судьбы"!+ Матрица на год
          </span>
          <p className={"learnP"}>
            <span className={"spanStar"}>Запишитесь на наш комплексный онлайн-курс и освойте все аспекты метода "Матрица Судьбы", получив в итоге диплом о профессиональной переподготовке.  Курс идеально подходит для тех, кто хочет углубить свои знания и получить востребованную профессию.
</span>
            <span className={"spanStar"}>Программа курса включает в себя 12 модулей:</span>
            <span className={"spanStar"}><img src={star} alt="" />Основы метода «Матрица Судьбы» (уроки 1-7):  Погружение в базовые принципы и концепции метода.</span>
            <span className={"spanStar"}><img src={star} alt="" />Энергии в Матрице Судьбы (уроки 8-32):  Подробный разбор энергетических составляющих матрицы и их влияния на жизнь человека.
</span>
            <span className={"spanStar"}><img src={star} alt="" />Основы техники и анализа точек в Матрице Судьбы (уроки 33-43):  Практическое освоение методов анализа и интерпретации данных матрицы.
 </span>
            <span className={"spanStar"}><img src={star}
                                              alt="" />Методика работы с Матрицей здоровья (уроки 44-45):  Изучение применения метода для анализа и улучшения здоровья.
</span>
            <span className={"spanStar"}><img src={star} alt="" />Методы прогнозирования в Матрице Судьбы (уроки 46-48):  Научитесь предсказывать будущие события и тенденции.
</span>
            <span className={"spanStar"}><img src={star} alt="" />Иерархия в Матрице судьбы (уроки 49-52):  Разберем иерархические связи и их влияние на судьбу.
</span>
            <span className={"spanStar"}><img src={star} alt="" />Энергоемкие арканы в Матрице судьбы (урок 53):  Изучение наиболее сильных и влиятельных арканов.
 </span>
            <span className={"spanStar"}><img src={star} alt="" />Детская Матрица судьбы (уроки 54-56):  Анализ матрицы для детей и особенности работы с ними.
</span>
            <span className={"spanStar"}><img src={star} alt="" />Матрица совместимости партнеров (уроки 57-58):  Определение совместимости в отношениях с помощью матрицы.
 </span>
            <span className={"spanStar"}><img src={star} alt="" />Позиционирование в социальных сетях через Матрицу судьбы (урок 59):  Используйте знания для продвижения в социальных сетях.
 </span>
            <span className={"spanStar"}><img src={star} alt="" />Зеркальная, закрытая матрица и энергии антиподы (уроки 60-62):  Разберем сложные аспекты матрицы.
</span>
            <span className={"spanStar"}><img src={star} alt="" />Основы консультирования клиентов (уроки 63-67):  Научитесь профессионально консультировать клиентов, используя знания Матрицы Судьбы.
</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>В конце курса вас ждет итоговый контроль для получения диплома о профессиональной переподготовке.</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>‼️ВАЖНО: Диплом выдается на базе вашего уже имеющегося средне-специального или высшего образования.</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>‼️Для зачисления на курс потребуется предоставить скриншот документа об образовании.</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>‼️Доступ к матрице на год.</span>
            <a style={{ fontWeight: "bold", color: "white" }} className={"spanStar"} href={pdfUrl}
               target="_blank" rel="noopener noreferrer">
              ‼️Свидетельство
            </a>
          </p>
          <div>
          <span className={"spanLearn"}>
            Такой диплом вы получаете после окончания данного курса
          </span>
            <div className={"imgDiv"}>
              <img
                className={"imgSertificate"}
                src={diplom}
                alt="Диплом"
                onClick={() => openModal(diplom)}
              />
            </div>
            {modalOpen && (
              <div className="modal-container">
                <div className="modal-overlay" onClick={closeModal}></div>
                <div className="modal-content">
                  <img src={selectedImage} alt="Full-screen" />
                </div>
              </div>
            )}
          </div>
          <div className={"divBoxPay"}>
            <span className={"price"} style={{ textDecoration: "line-through" }}>27 900 <p>рублей</p></span>
            <span className={"price"}>19 900 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}
              className={"baton"}
              to={"/pay"}
              state={{
                name: "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца",
                price: 1990000
              }}
            >
              Приобрести
            </Link>
          </div>
        </div>



        <div className={"learnDescription"}>
          <span className={"learnSpanKourse"}>
            Базовый курс по Матрицы судьбы
          </span>
          <p className={"learnP"}>
            <span className={"spanStar"}><img src={star} alt="" />Расчет матрицы судьбы</span>
            <span className={"spanStar"}><img src={star} alt="" />Погружение в три семерицы энергии 1-22 аркан</span>
            <span className={"spanStar"}><img src={star} alt="" />Карма и виды карм:</span>
            <span style={{ marginLeft: 36 }}>&mdash; Детско-родительская карма</span>
            <br />
            <span style={{ marginLeft: 36 }}>&mdash; Родовые программы</span>
            <br />
            <span style={{ marginLeft: 36 }}>&mdash; Кармический хвост</span>
            <br />
            <span style={{ marginLeft: 36 }}>&mdash; Программы в матрице судьбы</span>
            <br />
            <span className={"spanStar"}><img src={star} alt="" />Предназначение</span>
            <span className={"spanStar"}><img src={star} alt="" />Деньги в матрице судьбы</span>
            <span className={"spanStar"}><img src={star} alt="" />Таланты</span>
            <span className={"spanStar"}><img src={star} alt="" />Отношения в матрице судьбы</span>
            <span className={"spanStar"}><img src={star} alt="" />Портрет вашего идеального партнера</span>
            <span className={"spanStar"}><img src={star} alt="" />Карта здоровья в матрице</span>
            <span className={"spanStar"}><img src={star} alt="" />Методы прогнозирования в матрице судьбы</span>
            <span className={"spanStar"}><img src={star} alt="" />Зеркальные каналы в матрице</span>
            <span className={"spanStar"}><img src={star} alt="" />Урок по консультациям</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>По окончанию курса получаете сертификат</span>
          </p>
          <div>
          <span className={"spanLearn"}>
            Такой сертификат вы получаете после окончания данного курса
          </span>
            <div className={"imgDiv"}>
              <img
                className={"imgSertificate"}
                src={sertificateImage}
                alt="Сертификат"
                onClick={() => openModal(sertificateImage)}
              />
            </div>
            {modalOpen && (
              <div className="modal-container">
                <div className="modal-overlay" onClick={closeModal}></div>
                <div className="modal-content">
                  <img src={selectedImage} alt="Full-screen" />
                </div>
              </div>
            )}
          </div>
          <div className={"divBoxPay"}>
            <span className={"price"}>2 990 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}
              className={"baton"}
              to={"/pay"}
              state={{
                name: "Базовый курс матрицы",
                price: 299000
              }}
            >
              Приобрести
            </Link>
          </div>
        </div>
        <div className={"learnDescription"}>
          <span className={"learnSpanKourse"}>Урок по детской матрице</span>
          <p className={"learnP"}>
            <span className={"spanStar"} style={{ fontWeight: "bold" }}>Дополнительный тариф предлагает приобретение видео урока "Особенности разбора Детской матрицы" и методички,
            включая:</span>
            <span className={"spanStar"}><img src={star} alt="" />"Расшифровку Детской матрицы"</span>
            <span className={"spanStar"}><img src={star} alt="" />"Детско-родительские отношения" и анкету для родителей.</span>
            <span className={"spanStar"} style={{ fontWeight: "bold" }}>Методичка "Расшифровка Детской матрицы" содержит описание 22 энергий, адаптированные под детскую матрицу, негативные сценарии, навязанные родителями, и рекомендации для родителей относительно кружков и секций.</span>
            <span className={"spanStar"} style={{ fontWeight: "bold" }}>Методичка "Детско-родительские отношения" включает информацию о том, чему пришел научить ребенок, какие ошибки по отношению к родителям и детям могут быть совершены, и к чему важно прийти. </span>
            <span className={"spanStar"}
                  style={{ fontWeight: "bold" }}>Тариф также включает анкету для родителей.</span>
            <span className={"spanStar"}><img src={star} alt="" />Также просмотр детской матрицы через чакральную систему </span>
            <span className={"spanStar"}
                  style={{ fontWeight: "bold" }}>*Приобретается дополнительно к базовому обучению</span>
          </p>
          <div className={"divBoxPay"}>
            <span className={"price"}>1 990 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}
              className={"baton"}
              to={"/pay"}
              state={{
                name: "Курс детской матрицы",
                price: 199000
              }}
            >
              Приобрести
            </Link>
          </div>
        </div>
        <div className={"learnDescription"}>
          <span className={"learnSpanKourse"}>
            Урок по матрице совместимости
          </span>
          <p className={"learnP"}>
            <span className={"spanStar"}><img src={star} alt="" />Данный тариф включает в себя видео урок "Расчет матрицы совместимости и анализа матрицы совместимости"</span>
            <span className={"spanStar"}><img src={star} alt="" />А также методички , содержащую "Расшифровку матрицы совместимости с рекомендациями для проработки энергий на каждой позиции".</span>
            <span className={"spanStar"}
                  style={{ fontWeight: "bold" }}>*Приобретается дополнительно к базовому обучению</span>
          </p>
          <div className={"divBoxPay"}>
            <span className={"price"}>1 990 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}
              className={"baton"}
              to={"/pay"}
              state={{
                name: "Курс матрицы совместимости",
                price: 199000
              }}
            >
              Приобрести
            </Link>
          </div>
        </div>
      </div>
      <div className={"backColor otstup"}>
        <div className={"allPay"}>
          <div className={"listLearn"}>
            <Card className={"cardLearn"}>
              <span className={"spanLearnPay"}>Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица на год</span>
              <span className={"price"} style={{ textDecoration: "line-through", flexDirection: "column" }}>27 900 <p
                style={{ fontSize: 24, fontFamily: "Golos" }}>рублей</p></span>
              <span className={"price"} style={{ flexDirection: "column" }}>19 900 <p
                style={{ fontSize: 24, fontFamily: "Golos" }}>рублей</p></span>
              <ul>
                <li className={"ulPay"}>
                  <img src={mark} alt="" className={"ic"} />
                  Вы получаете программу онлайн-курса по Матрице Судьбы с получением диплома государственного образца
                </li>
                <li className={"ulPay"}>
                  <img src={mark} alt="" className={"ic"} />
                  Доступ к матрице на год
                </li>
              </ul>
              <div className={"divBoxPay"}>
                <Link
                  style={{ backgroundColor: "rgba(196, 157, 214, 1)", width: "100%" }}
                  className={"batonStandart"}
                  to={"/pay"}
                  state={{
                    name: "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица на год",
                    price: 1990000
                  }}
                >
                  Приобрести
                </Link>
              </div>
            </Card>
            <Card className={"cardLearn"}>
              <span className={"spanLearnPay"}>Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица навсегда</span>
              <span className={"price"} style={{ flexDirection: "column" }}>25 390 <p
                style={{ fontSize: 24, fontFamily: "Golos" }}>рублей</p></span>
              <ul>
                <li className={"ulPay"}>
                  <img src={mark} alt="" className={"ic"} />
                  Вы получаете программу онлайн-курса по Матрице Судьбы с получением диплома государственного образца
                </li>
                <li className={"ulPay"}>
                  <img src={mark} alt="" className={"ic"} />
                  Доступ к калькулятору навсегда
                </li>
              </ul>
              <div className={"divBoxPay"}>
                <Link
                  style={{ backgroundColor: "rgba(196, 157, 214, 1)", width: "100%" }}
                  className={"batonStandart"}
                  to={"/pay"}
                  state={{
                    name: "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица навсегда",
                    price: 2539000
                  }}
                >
                  Приобрести
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
