import { useEffect } from "react";

function RedirectToNonWWW() {
  useEffect(() => {
    if (window.location.hostname === "www.matricadyshi.ru") {
      window.location.href = "https://matricadyshi.ru" + window.location.pathname;
    }
  }, []);

  return null; // Компонент не рендерит ничего
}

export default RedirectToNonWWW;
