import * as React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, createTheme, FormControlLabel, Theme, ThemeProvider } from "@mui/material";
import {
  emailValidation,
  nameAndSurnameValidation,
  passValidation,
  phoneValidation
} from "../validations/validationRegistrationForm";
import { useAppDispatch, useAppSelector } from "../redux-store/store";
import { registrationThunk } from "../redux-store/registration-login-auth";
import { MuiTelInput } from "mui-tel-input";
import "./Login.scss";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { HashLink as Anchor } from "react-router-hash-link";
import { ChangeEvent } from "react";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
      primary: {
        main: "rgba(46, 32, 81, 1)" // цвет текста метки и текста ошибки
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "white",
            "--TextField-brandBorderColor": "rgba(46, 32, 81, 1)",
            "--TextField-brandBorderHoverColor": "rgba(46, 32, 81, 1)",
            "--TextField-brandBorderFocusedColor": "rgba(46, 32, 81, 1)",
            "& label.Mui-focused": {
              color: "rgba(46, 32, 81, 1)",
              fontFamily: "Golos"
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "rgba(46, 32, 81, 1)",
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)"
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)"
            },
            fontFamily: "Golos"
          }
        }
      }
    }
  });

interface FormRegistration {
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmpass: string;
  check: boolean;
  phoneInput: string;
}

export function RegistrationForm() {
  const outerTheme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const auth = useAppSelector((state) => state.registrationReducer.auth);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    getValues,
    control
  } = useForm<FormRegistration>({
    mode: "all"
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const password = getValues("password");
  const onSubmit: SubmitHandler<FormRegistration> = (data) => {
    dispatch(
      registrationThunk({
        name: data.name,
        surname: data.surname,
        email: data.email.toLowerCase(),
        password: data.password,
        check: data.check,
        phone: data.phoneInput
      })
    );
  };
  const onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setCheck(e.currentTarget.checked);
  };
  const policyFunc = (auth: boolean) => {
    if (auth) {
      return (
        <span style={{ fontSize: 16, color: "rgba(104, 74, 134, 1)" }} className={"spanOferta"}>
        Продолжая регистрацию, я принмаю условия{" "}
          <Link style={{ color: "rgba(104, 74, 134, 1)" }} to={"/oferta"}>Публичной оферты</Link>, подтверждая, что
        ознакомился(ась) с{" "}
          <Anchor style={{ color: "rgba(104, 74, 134, 1)" }} smooth to={"/oferta/#personalData"}>
          Политикой обработки персональных данных
        </Anchor>{" "}
          и даю свое согласие на обработку персональных данных
      </span>
      );
    } else {
      return (
        <span style={{ fontSize: 16, color: "rgba(104, 74, 134, 1)" }} className={"spanOferta"}>
        Продолжая регистрацию, я принмаю условия{" "}
          <Link style={{ color: "rgba(104, 74, 134, 1)" }} to={"/oferta"}>Публичной оферты</Link>, подтверждая, что
        ознакомился(ась) с{" "}
          <Anchor style={{ color: "rgba(104, 74, 134, 1)" }} smooth to={"/oferta/#personalData"}>
          Политикой обработки персональных данных
        </Anchor>{" "}
          и даю свое согласие на обработку персональных данных
      </span>
      );
    }
  };
  const pol = policyFunc(auth);
  return (
    <form className={"formLoginRgistr"} onSubmit={handleSubmit(onSubmit)}>
      <span className={"loginSpan"}>Регистрация</span>
      <div className={"divLogin"}>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            className={"formPassAndMail"}
            {...register("name", nameAndSurnameValidation)}
            label="Имя"
            multiline
            maxRows={4}
            InputLabelProps={{ style: { color: "rgba(46, 32, 81, 1)" } }}
            variant="standard"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
          />
        </ThemeProvider>
      </div>
      {!errors.name?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> : undefined}
      <div className={"divLogin"}>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            className={"formPassAndMail"}
            {...register("surname", nameAndSurnameValidation)}
            label="Фамилия"
            multiline
            InputLabelProps={{ style: { color: "rgba(46, 32, 81, 1)" } }}
            maxRows={4}
            variant="standard"
            error={!!errors.surname?.message}
            helperText={errors.surname?.message}
          />
        </ThemeProvider>
      </div>
      {!errors.surname?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> : undefined}
      <div className={"divLogin"}>
        <Controller
          control={control}
          rules={phoneValidation}
          name={"phoneInput"}
          render={({ field, fieldState }) => (
            <ThemeProvider theme={customTheme(outerTheme)}>
              <MuiTelInput
                InputLabelProps={{ style: { color: "rgba(46, 32, 81, 1)" } }}
                {...field}
                className={"formPassAndMail"}
                onlyCountries={["RU", "KZ", "BY", "AZ", "AM", "KG", "MD", "TJ", "TM", "UZ", "UA"]}
                defaultCountry={"RU"}
                variant="standard"
                label={"Телефон"}
                inputProps={{ maxLength: 20 }}
                helperText={fieldState.invalid ? errors.phoneInput?.message : ""}
                error={fieldState.invalid}
              />
            </ThemeProvider>
          )}
        ></Controller>
      </div>
      {!errors.phoneInput?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> : undefined}
      <div className={"divLogin"}>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            InputLabelProps={{ style: { color: "rgba(46, 32, 81, 1)" } }}
            {...register("email", emailValidation)}
            className={"formPassAndMail"}
            label="Email"
            multiline
            maxRows={4}
            variant="standard"
            error={!!errors.email?.message}
            helperText={errors.email?.message}
          />
        </ThemeProvider>
      </div>
      {!errors.email?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> : undefined}
      <div className={"divLogin"}>
        <FormControl className={"formPassAndMail"} variant="standard">
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{ style: { color: "rgba(46, 32, 81, 1)" } }}
              label={"Введите пароль"}
              variant={"standard"}
              error={!!errors.password?.message}
              {...register("password", passValidation)}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff style={{ color: "rgba(104, 74, 134, 1)" }} /> :
                      <Visibility style={{ color: "rgba(104, 74, 134, 1)" }} />}
                  </IconButton>
                </InputAdornment>
              }}
            />
          </ThemeProvider>
          {!errors.password?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
            <FormHelperText error={!!errors.password?.message}>
              {errors.password?.message}
            </FormHelperText>}
        </FormControl>
      </div>
      <div className={"divLogin"}>
        <FormControl className={"formPassAndMail"} variant="standard">
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              InputLabelProps={{ style: { color: "rgba(46, 32, 81, 1)" } }}
              label={"Повторите пароль"}
              variant={"standard"}
              error={!!errors.confirmpass?.message}
              {...register("confirmpass", {
                validate: {
                  passConf: (value: string) => {
                    if (password !== value) {
                      return "Пароли не совпадают";
                    }
                    return true;
                  }
                }
              })}
              type={showPassword1 ? "text" : "password"}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword1 ? <VisibilityOff style={{ color: "rgba(104, 74, 134, 1)" }} /> :
                      <Visibility style={{ color: "rgba(104, 74, 134, 1)" }} />}
                  </IconButton>
                </InputAdornment>
              }}
            />
          </ThemeProvider>
          {!errors.confirmpass?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
            <FormHelperText error={!!errors.confirmpass?.message}>
              {errors.confirmpass?.message}
            </FormHelperText>}
        </FormControl>
      </div>
      <FormControlLabel
        style={{ margin: "15px auto", color: "white" }}
        className={"saveMeCh"}
        control={<Checkbox onChange={onChangeCheck} style={{
          color: "rgba(104, 74, 134, 1)",
          width: 16,
          height: 16,
          backgroundColor: "white",
          margin: "0 10px 0 0",
          padding: 0,
          borderRadius: 0
        }} defaultChecked={false} />}
        label={pol}
      />
      <FormControlLabel
        className={"saveMe"}
        control={<Checkbox style={{
          color: "rgba(104, 74, 134, 1)",
          width: 16,
          height: 16,
          backgroundColor: "white",
          margin: "0 10px 0 0",
          padding: 0,
          borderRadius: 0
        }} defaultChecked {...register("check")} />}
        label="Запомнить меня"
      />
      <button
        style={{ width: "90%", marginBottom: '30px' }}
        className={`batonStandart + ' ' + ${!isValid || !check ? "dis" : ""}`}
        disabled={!isValid || !check}
        type={"submit"}
      >
        Зарегистрироваться
      </button>
    </form>
  );
}
